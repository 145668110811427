<template>
  <div>
    <v-row class="mx-10 mb-10">
      <v-col>
        <v-card
          :class="['inset', 'welfare-card', 'px-3', 'py-3']"
        >
          <v-card-title>
            <v-icon class="mr-3">
              mdi-bullhorn-variant-outline
            </v-icon>
            お知らせ
          </v-card-title>
          <v-card-text
            v-for="topic in topics"
            :key="topic.uuid"
            class="py-1"
          >
            ・{{ topic.body }}<!-- TODO リンク挿入可能に -->
          </v-card-text>
          <v-card-text
            v-if="!topics.length"
          >
            新しいお知らせはありません。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="isStaff"
      class="mx-10 mb-10"
    >
      <v-col>
        <v-card
          :class="['inset', 'welfare-card', 'px-3', 'py-3']"
        >
          <v-card-title>
            <v-icon class="mr-3">
              mdi-hammer-wrench
            </v-icon>
            管理者欄
          </v-card-title>
          <v-card-text class="py-1">
            <a href="/manage/order/">
              ・ポイント交換申請管理ページ
            </a>
          </v-card-text>
          <v-card-text class="py-1">
            <a href="/manage/item/list/">
              ・アイテム管理ページ
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mx-10 mb-10">
      <v-col>
        <v-row class="item-title-liner">
          <v-card-title>新着アイテム</v-card-title>
          <a href="/item/list">アイテム一覧 >>></a>
        </v-row>
        <v-row>
          <v-col
            v-for="item in newItems"
            :key="item.uuid"
            cols="4"
          >
            <v-card
              :class="['small-outset', 'welfare-card', 'px-3', 'py-3', 'mx-5']"
              @click="showDetail(item.uuid)"
            >
              <v-img
                v-if="item.mainImgSrc"
                height="180"
                width="auto"
                :src="item.mainImgSrc"
              />
              <v-img
                v-else
                height="250"
                src="@/assets/img/no_image.png"
              />
              <v-card-title
                class="text-truncate d-block"
                v-text="item.name"
              />
              <v-card-text>
                {{ item.price.toLocaleString() }} pt
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      v-if="ranking.length"
      class="mx-10 mb-10"
    >
      <v-col>
        <v-row>
          <v-card-title>交換数ランキング</v-card-title>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, i) in ranking"
            :key="item.uuid"
            cols="2"
          >
            <v-card
              :class="['small-outset', 'welfare-card', 'px-3', 'py-3', 'mx-3']"
              @click="showDetail(item.uuid)"
            >
              <v-card-title>
                <v-icon
                  v-if="i === 0"
                >
                  mdi-crown
                </v-icon>
                <v-icon
                  v-if="i === 1"
                >
                  mdi-crown-outline
                </v-icon>
                {{ i + 1 }}位
              </v-card-title>
              <v-img
                v-if="item.mainImgSrc"
                height="180"
                width="auto"
                :src="item.mainImgSrc"
              />
              <v-img
                v-else
                height="180"
                src="@/assets/img/no_image.png"
              />
              <v-card-title
                class="text-truncate d-block"
                v-text="item.name"
              />
              <v-card-text>
                {{ item.price.toLocaleString() }} pt
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    topics: {
      type: Array,
      default: () => []
    },
    newItems: {
      type: Array,
      default: () => []
    },
    ranking: {
      type: Array,
      default: () => []
    },
    isStaff: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    showDetail (uuid) {
      this.$emit('showDetail', this, uuid)
    }
  }
}
</script>

<style scoped>
.item-title-liner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
</style>
